import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const LogoSlider = ({ logos }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4, // Hiển thị 2 card trên màn hình mobile
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024, // Đối với màn hình có chiều rộng từ 768px trở xuống
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480, // Đối với màn hình có chiều rộng từ 480px trở xuống
        settings: {
          slidesToShow: 1, // Hiển thị 1 card trên màn hình mobile nhỏ
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings} className="bg-white">
      {logos.map((logo, index) => (
        <div key={index} className="bg-white h-52">
          <div className="bg-emerald-50 rounded-lg shadow-md mx-3 h-52 flex justify-center align-middle">
            <img className='h-36 w-auto m-auto' src={logo} alt={`Logo ${index + 1}`} />
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default LogoSlider;

