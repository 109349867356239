import React from "react";
// import { fetchData } from '../services/api';
import { Link } from "react-router-dom";

function NewsList({ newsList, id }) {
  return (
    <div className="m-3 p-2 flex flex-col justify-center items-center gap-1">
      {newsList?.map((item, index) => (
        <>
          {item.id !== id ? (
            <div
              className="flex flex-col lg:flex-row justify-center items-center gap-5 pt-5 lg:h-64 lg:pb-2 lg:pt-0"
              style={{
                borderBottom:
                  index !== newsList.length - 1 ? "1px solid #BBBBBB" : "",
              }}
            >
              <Link
                className=" lg:w-1/3 lg:h-full flex items-center"
                to={`?newsId=${item?.id}`}
              >
                <div className="overflow-hidden" style={{maxHeight: '100%'}}>
                  <img
                    className="w-full object-cover hover:scale-110 transition duration-500 cursor-pointer"
                    src={item.news_avatar[0]}
                    alt={item.news_title}
                  />
                </div>
              </Link>
              <div className="lg:w-2/3">
                <Link
                  className="text-black hover:text-[#1A49A5]"
                  to={`?newsId=${item?.id}`}
                >
                  <h4 className="font-bold text-2xl news-slider-text-title">{item.news_title}</h4>
                </Link>
                <p className="text-xl text-justify news-slider-text-description">{item.news_description}</p>
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      ))}
    </div>
  );
}

export default NewsList;
