import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logobkblue from "../../assets/images/logobkblue.png";
import logobkwhite from "../../assets/images/logobkwhite.png";
import blackMenu from "../../assets/images/icons/blackMenu.png";
import whiteMenu from "../../assets/images/icons/whiteMenu.png";

function Header() {
  const navigate = useNavigate();
  const isHomePage = window.location.pathname === "/";
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    // Logic xử lý khi link thay đổi
    setShowMenu(false);
    window.scrollTo(0, 0);
    console.log("Link has changed:", window.location.pathname);
  }, [navigate]);

  const navItems = [
    { to: "/", label: "Trang Chủ" },
    { to: "about", label: "Giới Thiệu" },
    { to: "products", label: "Sản Phẩm" },
    { to: "news", label: "Tin Tức" },
    { to: "/contacts", label: "Liên hệ" }, // Thay thế "#" bằng đường dẫn thực tế nếu cần
  ];

  return (
    <div className="relative z-10">
      <div
        className={`grid grid-cols-5 lg:grid-cols-8 gap-6 w-full px-4 h-14 md:h-24 lg:h-28 2xl:h-36 ${
          isHomePage ? "absolute" : "bg-white"
        }`}
      >
        <div className="col-span-4 lg:col-span-3 w-auto object-cover">
        <a href="/" className="flex w-fit">
          <img
            className={`h-14 md:h-24 lg:h-28 2xl:h-36`}
            src={isHomePage ? logobkwhite : logobkblue}
            alt="Background"
          />
        </a>
        </div>
        <nav className="hidden lg:col-span-5 lg:flex lg:items-center lg:justify-end lg:mr-5 2xl:text-3xl">
          <ul className="flex items-center gap-12 font-bold uppercase">
            {navItems.map((item, index) => (
              <li
                key={index}
                className={`text-${isHomePage ? "white" : "black"}`}
              >
                <Link
                  to={`${item.to}`}
                  className={`ct-text-link group ${
                    isHomePage ? "" : "text-black"
                  }`}
                >
                  {item.label}
                  {index === 0 && isHomePage ? (
                    <span
                      className={`ct-underline-link-active bg-white`}
                    ></span>
                  ) : index === 1 && window.location.pathname === '/about' ? (
                    <span
                      className={`ct-underline-link-active bg-black`}
                    ></span>
                  ) : index === 2 && window.location.pathname === '/products' ? (
                    <span
                      className={`ct-underline-link-active bg-black`}
                    ></span>
                  ) : index === 3 && window.location.pathname === '/news' ? (
                    <span
                      className={`ct-underline-link-active bg-black`}
                    ></span>
                  ) : index === 4 && window.location.pathname === '/contacts' ? (
                    <span
                      className={`ct-underline-link-active bg-black`}
                    ></span>
                  ) : (
                    <span
                      className={`ct-underline-link ${
                        isHomePage ? "bg-white" : "bg-black"
                      }`}
                    ></span>
                  )}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
        <a
          className="lg:hidden flex items-center justify-center z-30"
          onClick={(e) => setShowMenu(!showMenu)}
        >
          <img
            alt="menu"
            src={isHomePage && !showMenu ? whiteMenu : blackMenu}
            className="h-5 w-5 m-auto text-white lg:hidden"
          />
        </a>
      </div>
      {showMenu && (
        <div
          className={`w-full h-auto lg:hidden bg-white pt-10 z-20 transition-all duration-300
        ${isHomePage ? "absolute" : ""}`}
        >
          <ul className="flex flex-col items-start gap-1 pl-5 font-bold uppercase">
            {navItems.map((item, index) => (
              <li
                key={index}
                className={` h-full text-${
                  isHomePage && !showMenu ? "white" : "black"
                }`}
              >
                <Link
                  to={`${item.to}`}
                  className={`pb-1 sm:pb-3 relative inline-block group ${
                    isHomePage && !showMenu ? "" : "text-black"
                  }`}
                >
                  {item.label}
                  <span
                    className={`ct-underline-link bg-${
                      isHomePage && !showMenu ? "white" : "black"
                    }`}
                  ></span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default Header;
