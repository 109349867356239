import React from "react";
// import { fetchData } from '../services/api';
import lms from "../../assets/images/Products/lms.png";
import diemdanh from "../../assets/images/Products/diemdanh.png";
import kiemdinh from "../../assets/images/Products/kiemdinh.png";
import solienlac from "../../assets/images/Products/solienlac.png";
import thidua from "../../assets/images/Products/thidua.png";
import tkb from "../../assets/images/Products/tkb.png";

import pgdq3 from "../../assets/images/Schools/Customers/PGD/PGD quận 3.jpg";
import pgdq4 from "../../assets/images/Schools/Customers/PGD/PGD quận 4.jpg";
import pgdq7 from "../../assets/images/Schools/Customers/PGD/PGD quận 7.jpg";
import pgdqTP from "../../assets/images/Schools/Customers/PGD/PGD quận Tân Phú.jpg";
import pgdqTD from "../../assets/images/Schools/Customers/PGD/PGD TP Thủ Đức.jpg";
import pgdqCG from "../../assets/images/Schools/Customers/PGD/PGD huyện Cần Giờ.png";

import thpt1 from "../../assets/images/Schools/Customers/Schools/THPT/1. THPT Lê Hồng Phong.png";
import thpt2 from "../../assets/images/Schools/Customers/Schools/THPT/2. THPT Lê Quý Đôn.png";
import thpt3 from "../../assets/images/Schools/Customers/Schools/THPT/THPT Phú NHuận.jpg";
import thpt4 from "../../assets/images/Schools/Customers/Schools/THPT/5.THPT Trần Khai Nguyên.png";
import thpt5 from "../../assets/images/Schools/Customers/Schools/THPT/6.THPT Nguyễn Văn Tăng.jpg";
import thpt6 from "../../assets/images/Schools/Customers/Schools/THPT/4. THPT Nguyễn Công Trứ.png";
import thcs1 from "../../assets/images/Schools/Customers/Schools/THCS/1. THCS Ngô Chí Quốc.png";
import thcs2 from "../../assets/images/Schools/Customers/Schools/THCS/2. THCS Lý Phong.png";
import thcs3 from "../../assets/images/Schools/Customers/Schools/THCS/3. THCS Lê Anh Xuân.png";
import thcs4 from "../../assets/images/Schools/Customers/Schools/THCS/4. THCS Lê Quý Đôn.jpg";
import thcs5 from "../../assets/images/Schools/Customers/Schools/THCS/5.THCS Phạm Hữu Lầu.jpg";
import thcs6 from "../../assets/images/Schools/Customers/Schools/THCS/6.THCS Lý Thánh Tông.jpg";
import th1 from "../../assets/images/Schools/Customers/Schools/TH/1. Tiểu học Hiệp Tân.jpg";
import th2 from "../../assets/images/Schools/Customers/Schools/TH/2. Tiểu học Nguyễn Thiện Thuật.jpg";
import th3 from "../../assets/images/Schools/Customers/Schools/TH/3. Tiểu học Phan Chu Trinh.png";
import th4 from "../../assets/images/Schools/Customers/Schools/TH/4. Tiểu học Ngô Quyền.jpg";
import th5 from "../../assets/images/Schools/Customers/Schools/TH/5. Tiểu học Nguyễn Văn Trỗi.jpg";
import th6 from "../../assets/images/Schools/Customers/Schools/TH/6. Tiểu học Cần Thạnh.png";

function Products() {
  const productsList = [
    {
      name: "LMS360 e-Learning",
      img: lms,
      link: "https://lms360.edu.vn/",
    },
    {
      name: "Hệ thống kiểm định chất lượng Giáo dục",
      img: kiemdinh,
      link: "https://kiemdinh.hcm.edu.vn/",
    },
    {
      name: "Hệ thống đánh giá - Thi đua khen thưởng",
      img: thidua,
      link: "https://thiduakhenthuong.hcm.edu.vn",
    },
    {
      name: "Sắp xếp TKB",
      img: tkb,
      link: "",
    },
    {
      name: "Điểm danh nhận diện khuôn mặt bằng AI",
      img: diemdanh,
      link: "",
    },
    {
      name: "Sổ liên lạc điện tử",
      img: solienlac,
      link: "",
    },
  ];

  const customersList = [
    {
      type: "Phòng GD&ĐT Quận 3",
      img: pgdq3,
      link: "",
    },
    {
      type: "Phòng GD&ĐT Quận 4",
      img: pgdq4,
      link: "",
    },
    {
      type: "Phòng GD&ĐT Quận 7",
      img: pgdq7,
      link: "",
    },
    {
      type: "Phòng GD&ĐT Quận Tân Phú",
      img: pgdqTP,
      link: "",
    },
    {
      type: "Phòng GD&ĐT TP Thủ Đức",
      img: pgdqTD,
      link: "",
    },
    {
      type: "Phòng GD&ĐT Huyện Cần Giờ",
      img: pgdqCG,
      link: "",
    },
    {
      type: "Trường THPT",
      name: "Lê Hồng Phong",
      img: thpt1,
      link: "",
    },
    {
      type: "Trường THPT",
      name: "Lê Quý Đôn",
      img: thpt2,
      link: "",
    },
    {
      type: "Trường THPT",
      name: "Phú Nhuận",
      img: thpt3,
      link: "",
    },
    {
      type: "Trường THPT",
      name: "Trần Khai Nguyên",
      img: thpt4,
      link: "",
    },
    {
      type: "Trường THPT",
      name: "Nguyễn Văn Tăng",
      img: thpt5,
      link: "",
    },
    {
      type: "Trường THPT",
      name: "Nguyễn Công Trứ",
      img: thpt6,
      link: "",
    },
    {
      type: "Trường THCS",
      name: "Ngô Chí Quốc",
      img: thcs1,
      link: "",
    },
    {
      type: "Trường THCS",
      name: "Lý Phong",
      img: thcs2,
      link: "",
    },
    {
      type: "Trường THCS",
      name: "Lê Anh Xuân",
      img: thcs3,
      link: "",
    },
    {
      type: "Trường THCS",
      name: "Lê Quý Đôn",
      img: thcs4,
      link: "",
    },
    {
      type: "Trường THCS",
      name: "Phạm Hữu Lầu",
      img: thcs5,
      link: "",
    },
    {
      type: "Trường THCS",
      name: "Lý Thánh Tông",
      img: thcs6,
      link: "",
    },
    {
      type: "Trường Tiểu học",
      name: "Hiệp Tân",
      img: th1,
      link: "",
    },
    {
      type: "Trường Tiểu học",
      name: "Nguyễn Thiện Thuật",
      img: th2,
      link: "",
    },
    {
      type: "Trường Tiểu học",
      name: "Phan Chu Trinh",
      img: th3,
      link: "",
    },
    {
      type: "Trường Tiểu học",
      name: "Ngô Quyền",
      img: th4,
      link: "",
    },
    {
      type: "Trường Tiểu học",
      name: "Nguyễn Văn Trỗi",
      img: th5,
      link: "",
    },
    {
      type: "Trường Tiểu học",
      name: "Cần Thạnh",
      img: th6,
      link: "",
    },
  ];

  return (
    <div className="flex flex-col min-h-screen bg-sky-50">
      <div className="relative z-10 mt-2 px-5 lg:px-24 mb-10">
        <div className="ct-sub-container-home" style={{ marginBottom: "50px" }}>
          <div className="flex flex-row justify-center mb-5">
            <h3 className="ct-text-title">
              Sản phẩm tiêu biểu
              <span className="ct-underline-title"></span>
            </h3>
          </div>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
            {productsList.map((item, index) => (
              <div className="col-span-1 w-3/4 m-2 max-w-sm mx-auto overflow-hidden shadow-lg flex flex-col justify-center items-center rounded-xl border border-solid border-[#367AE2] hover:transform hover:scale-110 transition-transform duration-300">
                <div className="px-2 lg:px-6 py-4 h-1/3 flex justify-center items-center">
                  <div className="font-bold lg:text-xl mb-2 text-center">
                    {item.name}
                  </div>
                </div>
                <div className="bg-[#367AE2] w-full flex flex-col justify-between items-center p-2 h-2/3">
                  <img
                    className="w-fit h-1/2 mt-5 rounded-md"
                    src={item.img}
                    alt={item.name}
                  />
                  <a
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="py-3 px-2 lg:px-5 h-fit w-fit bg-white text-black hover:underline uppercase text-sm lg:text-xl font-bold rounded-xl flex flex-col justify-center items-center"
                  >
                    Xem thêm
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="ct-sub-container-home">
          <div className="flex flex-row justify-center">
            <h3 className="ct-text-title">
              Khách hàng tiêu biểu
              <span className="ct-underline-title"></span>
            </h3>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-2">
            {customersList.map((item, index) => (
              <div className="col-span-1 w-full h-fit m-2 max-w-sm mx-auto overflow-hidden flex flex-col justify-center items-center rounded-xl bg-sky-100 shadow-xl shadow-sky-50 hover:transform hover:scale-110 transition-transform duration-300">
                <img
                  className="w-fit h-36 mt-2 rounded-md"
                  src={item.img}
                  alt={item.name}
                />
                <div className="p-1 h-16 flex flex-col justify-center items-center">
                  <div className="text-xl font-medium text-center md:px-2">
                    {item.type}
                  </div>
                  <div className="text-md font-medium text-center">
                    {item.name}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Products;
