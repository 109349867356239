import React, { useState, useEffect, useRef } from "react";
import NewsList from "./components/NewsList";
import callApi from "../../services/apiCaller";
import config from "../../common/config";
import { useLocation } from "react-router-dom";

function News() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const newsId = queryParams.get("newsId") || -1;

  const [listNews, setListNews] = useState([]);
  const [content, setContent] = useState([]);
  const [contentId, setContentId] = useState(-1);

  const contentRef = useRef(null);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setTimeout(() => {
      if (newsId >= 0) {
        getElearningNewsById();
        setContentId(parseInt(newsId));
      }
    }, 200);
  }, [newsId]);

  useEffect(() => {
    getNewsList();
  }, []);

  const getNewsList = async () => {
    const apiBaseUrl =
      process.env.REACT_APP_DOMAIN_API +
      "/gd/v3/h5p/elearning_news?page_id=1&per_page=-1&keyword=";
    try {
      const response = await callApi(apiBaseUrl, "GET", null);
      if (response && response.data && response.data.data.records) {
        setListNews(response.data.data.records);
      }
    } catch (error) {
      console.error("Error fetching news list:", error);
    }
  };

  async function getElearningNewsById() {
    const apiBaseUrl =
      process.env.REACT_APP_DOMAIN_API + "/gd/v3/h5p/elearning_news/" + newsId;
    try {
      const response = await callApi(apiBaseUrl, "GET", null);
      if (response && response.data && response.data.data.item) {
        setContent(response.data.data.item);
      }
    } catch (error) {
      console.error("Error fetching news by id:", error);
    }
  }

  return (
    <div className="flex flex-col min-h-screen bg-sky-50">
      <div className="relative z-10 mt-2 px-5 lg:px-24 mb-10">
        {contentId === -1 ? (
          <div className="ct-sub-container-home">
            <div className="flex flex-row justify-start mb-5">
              <h3 className="ct-text-title">
                Bản tin bách khoa
                <span className="ct-underline-title"></span>
              </h3>
            </div>
            {<NewsList newsList={listNews} id={contentId} />}
          </div>
        ) : (
          <div className="ct-sub-container-home">
            <div className="flex flex-row justify-start mb-5">
              <h3 className="ct-text-title">
                Bản tin bách khoa
                <span className="ct-underline-title"></span>
              </h3>
            </div>
            <div className="p-5 pt-1">
              <div>
                <div
                  className="news-content"
                  dangerouslySetInnerHTML={{ __html: content.news_content }}
                />
              </div>
            </div>
            <div className="ct-sub-container-home">
              <div className="flex flex-row justify-start mb-5">
                <h3 className="ct-text-title">
                  Tin khác
                  <span className="ct-underline-title"></span>
                </h3>
              </div>
              {<NewsList newsList={listNews} id={contentId} />}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default News;
